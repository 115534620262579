// TEST
require("knockout");
require("datatables.net");
require("moment");
// require("moment-duration-format");

import * as ko from "knockout";
//import * as moment from "moment";
import "../common/bindingHandlers";


import {
    getJSON,
    postJSON,
} from "../common/helper";

import {
    GridViewModel,
} from "../common/gridmodel";

import * as signalR from "@aspnet/signalr";

import {
    TypedEvent,
} from "../common/typedevent";

import {
    NumberOrNull,
    StringOrNull,
    StringOrUndefined,
} from "../common/types";

import {
    PageRequest,
    SortDirection,
} from "../common/request";

import {
    AccountProposalResultPO,
    ImportServiceProjectPO,
    NewAccountResultPO,
    NewAccountUpdateResponsePO,
    RangeResponsePO,
    UploadDataResponsePO,
    UploadResultPO,
    AnswerResultPO,
    ProgressPO
} from "./po2";
import { request } from "https";

class NewAccountUpdateRequest {
    // -----------------------------------------------------------
    // Aktualisiert einen NewAccount mit einem AccountProposal
    // -----------------------------------------------------------
    public id: number;
    public accountProposalName: StringOrNull;
    public accountProposalId: NumberOrNull;
    public factor: NumberOrNull;
    public createNew: boolean;

    public constructor(
        id: number, accountProposalName: StringOrNull,
        accountProposalId: NumberOrNull, factor: NumberOrNull, createNew: boolean) {

        this.id = id;
        this.accountProposalName = accountProposalName;
        this.accountProposalId = accountProposalId;
        this.factor = factor;
        this.createNew = createNew;
    }
}

class AccountProposalPageRequest extends PageRequest {
    // -----------------------------------------------------------
    // Fragt AccountProposal-Daten beim Server nach - Paging
    // -----------------------------------------------------------
    public id: number;
    public accountProposalId: NumberOrNull;
    public sensitivity: number;
    public cluster: number;
    public loadFromCache: boolean;

    public constructor(
        page: number, itemsPerPage: number, sort: SortDirection, sortColumn: string, filter: string,
        id: number, accountProposalId: NumberOrNull, sensitivity: number, cluster: number, loadFromCache: boolean) {

        super(page, itemsPerPage, sort, sortColumn, filter);
        this.id = id;
        this.accountProposalId = accountProposalId;
        this.sensitivity = sensitivity;
        this.cluster = cluster;
        this.loadFromCache = loadFromCache;
    }
}

class IsBusyChangedEventArg {
    public isbusy: boolean;

    constructor(isbusy: boolean) {
        this.isbusy = isbusy;
    }
}

class IsBusyChangedEvent extends TypedEvent<IsBusyChangedEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn das Hochladen ewrfolgreich war
    // -----------------------------------------------------------
}

class VisibilityChangedEventArg {
    public visible: boolean;

    constructor(visible: boolean) {
        this.visible = visible;
    }
}

class VisibilityChangedEvent extends TypedEvent<VisibilityChangedEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn das Hochladen ewrfolgreich war
    // -----------------------------------------------------------
}

class IdentifiedEventArg {
}

class IdentifiedEventEvent extends TypedEvent<IdentifiedEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn das Hochladen ewrfolgreich war
    // -----------------------------------------------------------
}

class UpdateStatisticsEventArg {
    public result: UploadResultPO;

    constructor(result: UploadResultPO) {
        this.result = result;
    }
}

class UpdateStatisticsEvent extends TypedEvent<UpdateStatisticsEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn das Hochladen ewrfolgreich war
    // -----------------------------------------------------------
}

class DataUploadedEventArg {
    public fileName: string;
    public countRecords: number;

    constructor(fileName: string, countRecords: number) {
        this.fileName = fileName;
        this.countRecords = countRecords;
    }
}

class DataUploadedEvent extends TypedEvent<DataUploadedEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn das Hochladen ewrfolgreich war
    // -----------------------------------------------------------
}

class Range {
    // -----------------------------------------------------------
    // UI-Klasse für Range
    // -----------------------------------------------------------
    public range: ko.Observable<StringOrNull>;
    public count: ko.Observable<NumberOrNull>;

    constructor(range: StringOrNull, count: NumberOrNull) {
        this.range = ko.observable(range);
        this.count = ko.observable(count);
    }
}

class NewAccount {
    // -----------------------------------------------------------
    // UI-Klasse für NewAccount
    // -----------------------------------------------------------
    public id: ko.Observable<number>;
    public extId: ko.Observable<StringOrNull>;
    public name: ko.Observable<StringOrNull>;
    public accountProposal: ko.Observable<StringOrNull>;
    public accountProposalId: ko.Observable<NumberOrNull>;
    public companyProposal: ko.Observable<StringOrNull>;
    public companyProposalId: ko.Observable<NumberOrNull>;
    public factor: ko.Observable<NumberOrNull>;
    public fullName: ko.Observable<StringOrNull>;
    public isSelected: ko.Observable<string>;

    constructor(
        id: number,
        extId: string,
        name: string,
        accountProposal: string,
        accountProposalId: number,
        companyProposal: string,
        companyProposalId: number,
        fullName: string,
        factor: NumberOrNull) {

        this.id = ko.observable(id);
        this.extId = ko.observable(extId);
        this.name = ko.observable(name);
        this.accountProposal = ko.observable(accountProposal);
        this.accountProposalId = ko.observable(accountProposalId);
        this.companyProposal = ko.observable(companyProposal);
        this.companyProposalId = ko.observable(companyProposalId);
        this.factor = ko.observable(factor);
        this.isSelected = ko.observable("bg-info text-light hand");
        this.fullName = ko.observable(fullName);
    }

    public equals(anotherNewAccount: NewAccount | undefined): boolean {
        if (anotherNewAccount === undefined) {
            return false;
        }

        return anotherNewAccount.id() === this.id();
    }
}

class AccountProposal {
    // -----------------------------------------------------------
    // UI-Klasse für AccountProposal
    // -----------------------------------------------------------
    public id: ko.Observable<number>;
    public name: ko.Observable<StringOrNull>;
    public factor: ko.Observable<number>;

    constructor(id: number, name: string, factor: number) {
        this.id = ko.observable(id);
        this.name = ko.observable(name);
        this.factor = ko.observable(factor);
    }
}

class ChangePackageRequest {
    public projectName: string;

    constructor(projectName: string) {
        this.projectName = projectName;
    }
}

// -----------------------------------------------------------
// Custom-Ereignisklassen
// -----------------------------------------------------------
class NewAccountSelectionChangedEventArgs {
    public newAccount: NewAccount | undefined;

    constructor(newAccount: NewAccount | undefined) {
        this.newAccount = newAccount;
    }
}

class NewAccountSelectionChangedEvent extends TypedEvent<NewAccountSelectionChangedEventArgs> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn sich die Selektion bei
    // den hochgeladenen Accounts ändert
    // -----------------------------------------------------------
}

class AssignAccountProposalEventArg {
    public accountProposal: AccountProposal;

    constructor(accountProposal: AccountProposal) {
        this.accountProposal = accountProposal;
    }
}

class AssignAccountProposalEvent extends TypedEvent<AssignAccountProposalEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn ein bestehender Account
    // zu einem hochgeladenen Accounts gesetzt werden soll
    // -----------------------------------------------------------
}

class RemoveAccountProposalEventArg {
    public newAccount: NewAccount;

    constructor(newAccount: NewAccount) {
        this.newAccount = newAccount;
    }
}

class RemoveAccountProposalEvent extends TypedEvent<RemoveAccountProposalEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn ein bestehender Account
    // von einem hochgeladenen Accounts getrennt werden soll
    // -----------------------------------------------------------
}

class CreateAccountEventArg {
}

class CreateAccountEvent extends TypedEvent<CreateAccountEventArg> {
    // -----------------------------------------------------------
    // Ereignis wird herausgeworfen, wenn ein bestehender Account
    // von einem hochgeladenen Accounts getrennt werden soll
    // -----------------------------------------------------------
}

class RangesViewModel {
    public isBusyChangedEvent: IsBusyChangedEvent;
    public items: ko.ObservableArray<Range>;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor() {
        this.items = ko.observableArray();
        this.isBusyChangedEvent = new IsBusyChangedEvent();
    }

    public loadData = () => {
        // -----------------------------------------------------------
        // Lade Daten vom Server herunter
        // -----------------------------------------------------------

        const success = (result: RangeResponsePO) => {
            const self = this as RangesViewModel;
            self.items.removeAll();

            result.ranges.forEach((a) => {
                self.items.push(
                    new Range(
                        a.range,
                        a.count,
                    ));
            });
            this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
        };

        const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
            this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
        };

        getJSON(
            {
                context: this,
                error,
                success,
                url: "/api/ImportService/GetRanges",
            });
    }
}

class NewAccountPageRequest extends PageRequest {
    // -----------------------------------------------------------
    // Fragt NewAccount-Daten beim Server nach - Paging
    // -----------------------------------------------------------

    public constructor(page: number, itemsPerPage: number, sort: SortDirection, sortColumn: string, filter: string) {
        super(page, itemsPerPage, sort, sortColumn, filter);
    }
}

// -----------------------------------------------------------
// NewAccountViewModel
// -----------------------------------------------------------
class NewAccountGridModel extends GridViewModel<NewAccount> {
    public selectionChangedEvent: NewAccountSelectionChangedEvent;
    public removeElementEvent: RemoveAccountProposalEvent;
    public createAccountEvent: CreateAccountEvent;
    public visible: ko.Observable<boolean>;
    public selectedNewAccount: ko.Observable<NewAccount | undefined>;
    public isBusyChangedEvent: IsBusyChangedEvent;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor(
        defaultSortColumn: string, dir: SortDirection, itemsPerPage?: number,
        itemsPerPageList?: number[]) {

        super("NewAccountViewModel", defaultSortColumn, dir, itemsPerPage, itemsPerPageList);

        this.createAccountEvent = new CreateAccountEvent();
        this.selectionChangedEvent = new NewAccountSelectionChangedEvent();
        this.removeElementEvent = new RemoveAccountProposalEvent();
        this.selectedNewAccount = ko.observable(undefined);
        this.visible = ko.observable(false);
        this.isBusyChangedEvent = new IsBusyChangedEvent();
    }

    // -----------------------------------------------------------
    // Eigenschaften
    // -----------------------------------------------------------
    public isSelected = (newAccount: NewAccount) => {
        if (this.selectedNewAccount() !== undefined) {
            return this.selectedNewAccount()!.equals(newAccount);
        }

        return false;
    }

    // -----------------------------------------------------------
    // UI-Ereignisse
    // -----------------------------------------------------------
    public selectRow = (newAccount: NewAccount, event: Event) => {
        this.selectNewAccount(newAccount);
    }

    public onUpdateItemClick = (newAccount: NewAccount) => {
        newAccount.accountProposal(null);
        newAccount.fullName(null);
        newAccount.accountProposalId(null);
        this.updateNewAccount(newAccount);
        this.removeElementEvent.emit(new RemoveAccountProposalEventArg(newAccount));
    }

    public onCreateItemClick = (newAccount: NewAccount) => {
        newAccount.accountProposal(newAccount.name());
        newAccount.fullName(newAccount.name());
        newAccount.accountProposalId(null);
        this.createAccount(newAccount);
    }

    // -----------------------------------------------------------
    // Benutzerereignisse
    // -----------------------------------------------------------
    public onVisibilityChanged = (event: VisibilityChangedEventArg) => {
        this.visible(event.visible);
        this.loadDataAndReset(true);
    }

    public onDataUploaded = (event: DataUploadedEventArg) => {
        this.loadDataAndReset(true);
    }

    public onAssignElement = (event: AssignAccountProposalEventArg) => {
        if (this.selectedNewAccount !== undefined) {
            this.selectedNewAccount()!.accountProposal(event.accountProposal.name());
            this.selectedNewAccount()!.accountProposalId(event.accountProposal.id());
            this.selectedNewAccount()!.fullName(event.accountProposal.name());
            this.selectedNewAccount()!.factor(event.accountProposal.factor());
            this.updateNewAccount(this.selectedNewAccount()!);
        }
    }

    // -----------------------------------------------------------
    // Öffentliche Methoden
    // -----------------------------------------------------------
    public updateNewAccount = (newAccount: NewAccount) => {
        const request =
            new NewAccountUpdateRequest(
                newAccount.id(),
                newAccount.accountProposal(),
                newAccount.accountProposalId(),
                newAccount.factor(),
                false);

        postJSON(
            {
                context: this,
                data: JSON.stringify(request),
                url: "/api/ImportService/Update",
            });
    }

    public createAccount = (newAccount: NewAccount) => {
        const request =
            new NewAccountUpdateRequest(
                newAccount.id(),
                newAccount.accountProposal(),
                newAccount.accountProposalId(),
                newAccount.factor(),
                true);

        const success = (result: NewAccountUpdateResponsePO) => {
            this.createAccountEvent.emit(new CreateAccountEventArg());
        };

        postJSON(
            {
                context: this,
                data: JSON.stringify(request),
                success,
                url: "/api/ImportService/Update",
            });
    }

    public loadData = () => {
        // -----------------------------------------------------------
        // Lade Daten vom Server herunter, Paging inbegriffen
        // -----------------------------------------------------------
        const request =
            new NewAccountPageRequest(
                this.pager.page,
                this.itemsPerPage(),
                this.dir!,
                this.lastColumn,
                this.filter(),
            );

        const success = (result: NewAccountResultPO) => {
            const self = this as NewAccountGridModel;
            self.items.removeAll();

            result.newAccounts.forEach((a) => {
                self.items.push(
                    new NewAccount(
                        a.id,
                        a.extId,
                        a.name,
                        a.accountProposal,
                        a.accountProposalId,
                        a.companyProposal,
                        a.companyProposalId,
                        a.fullName,
                        a.factor));
            });

            self.totalCount(result.totalCount);

            if (self.items().length > 0) {
                this.selectNewAccount(self.items()[0]);
            } else {
                this.selectNewAccount(undefined);
            }

            self.updatePaging();
        };

        const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
            this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
        };

         postJSON(
            {
                context: this,
                data: JSON.stringify(request),
                error,
                success,
                url: "/api/ImportService/GetNewAccount",
            });
    }

    // -----------------------------------------------------------
    // Private Methoden
    // -----------------------------------------------------------
    private selectNewAccount = (newAccount: NewAccount | undefined) => {
        this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
        this.selectedNewAccount(newAccount);
        this.selectionChangedEvent.emit(new NewAccountSelectionChangedEventArgs(newAccount));
    }
}

class AccountProposalGridModel extends GridViewModel<AccountProposal> {
    public sensitivity: ko.Observable<number>;
    public cluster: ko.Observable<number>;
    public assignElementEvent: AssignAccountProposalEvent;
    public visible: ko.Observable<boolean>;
    public loadFromCache: boolean;
    public isBusyChangedEvent: IsBusyChangedEvent;
    public newAccountGridModel: ko.Observable<NewAccountGridModel>;
    private selectedNewAccount: NewAccount | undefined;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor(
        defaultSortColumn?: string,
        dir?: SortDirection,
        itemsPerPage?: number,
        itemsPerPageList?: number[]) {

        super("AccountProposalViewModel", defaultSortColumn, dir, itemsPerPage, itemsPerPageList);
        this.itemsPerPage(itemsPerPage ? itemsPerPage : 5);
        this.assignElementEvent = new AssignAccountProposalEvent();
        this.visible = ko.observable(false);
        this.sensitivity = ko.observable(80);
        this.cluster = ko.observable(95);
        this.loadFromCache = false;
        this.isBusyChangedEvent = new IsBusyChangedEvent();
    }

    // -----------------------------------------------------------
    // UI-Ereignisse
    // -----------------------------------------------------------
    public onItemClick = (accountProposal: AccountProposal) => {
        this.assignElementEvent.emit(new AssignAccountProposalEventArg(accountProposal));
    }

    public onRefresh = (accountProposal: AccountProposal) => {
        this.refresh();
    }

    // -----------------------------------------------------------
    // Benutzerereignisse
    // -----------------------------------------------------------
    public onCreateAccount = (event: CreateAccountEvent) => {
        this.refresh();
    }

    public onVisibilityChanged = (event: VisibilityChangedEventArg) => {
        this.visible(event.visible);
    }

    public onSelectionChanged = (event: NewAccountSelectionChangedEventArgs) => {
        this.selectedNewAccount = event.newAccount;
        this.loadFromCache = false;
        this.loadDataAndReset(false);
    }

    // -----------------------------------------------------------
    // Öffentliche Methoden
    // -----------------------------------------------------------
    public loadData = (forceSpinner: boolean) => {
        if (this.selectedNewAccount !== undefined) {
            const request =
                new AccountProposalPageRequest(
                    this.pager.page,
                    this.itemsPerPage(),
                    this.dir!,
                    this.lastColumn,
                    this.filter(),
                    this.selectedNewAccount.id(),
                    this.selectedNewAccount.accountProposalId(),
                    this.sensitivity(),
                    this.cluster(),
                    this.loadFromCache,
                );

            const success = (result: AccountProposalResultPO) => {
                const self = this as AccountProposalGridModel;
                self.items.removeAll();

                result.accountProposals.forEach((a) => {
                    self.items.push(
                        new AccountProposal(a.id, a.name, a.factor));
                });

                self.totalCount(result.totalCount);

                self.loadFromCache = true;
                self.updatePaging();
                self.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
            };

            const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
                textStatus = "";
            };

            this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));

             postJSON(
                {
                    context: this,
                    data: JSON.stringify(request),
                    error,
                    success,
                    url: "/api/ImportService/GetAccountProposal",
                });
        } else {
            this.items.removeAll();
        }
    }

    private refresh = () => {
        this.loadFromCache = false;
        this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
        this.loadData(true);
    }
}

// -----------------------------------------------------------
// UploadData
//
// Beinhaltet Datenupload zum Server
// -----------------------------------------------------------
class UploadDataRequest {
    public fileName: string;
    public fileData: string | ArrayBuffer | null;
    public date: number;
    public size: number;
    public type: string;
}

//class UploadViewModelOld {
//    public fileName: ko.Observable<string>;
//    public info: ko.Observable<StringOrUndefined>;
//    public info2: ko.Observable<StringOrUndefined>;
//    public infoType: ko.Observable<string>;
//    public showInfo: ko.Computed<boolean>;
//    public visibiltyChangedEvent: VisibilityChangedEvent;
//    public isBusyChangedEvent: IsBusyChangedEvent;
//    public dataUploadedEvent: DataUploadedEvent;
//    public countRecords: ko.Observable<NumberOrNull>;
//    public countDublettes: ko.Observable<NumberOrNull>;

//    // -----------------------------------------------------------
//    // Konstruktor
//    // -----------------------------------------------------------
//    constructor() {
//        this.fileName = ko.observable("");
//        this.infoType = ko.observable("");
//        this.info = ko.observable(undefined);
//        this.info2 = ko.observable(undefined);
//        this.countRecords = ko.observable(null);
//        this.countDublettes = ko.observable(null);
//        this.visibiltyChangedEvent = new VisibilityChangedEvent();
//        this.dataUploadedEvent = new DataUploadedEvent();
//        this.isBusyChangedEvent = new IsBusyChangedEvent();
//        this.showInfo = ko.computed(() => {
//            return this.infoType() !== "";
//        }, this);
//    }

//    // -----------------------------------------------------------
//    // UI-Ereignisse
//    // -----------------------------------------------------------
//    public upload = (model: UploadViewModel, evt: Event) => {
//        const input = evt.target as HTMLInputElement;

//        if (input.files === null) {
//            return;
//        }

//        if (input.files.length === 0) {
//            return;
//        }

//        const uploadFile = input.files[0];
//        const reader = new FileReader();

//        const sendData = new UploadDataRequest();
//        sendData.fileName = uploadFile.name;
//        sendData.date = uploadFile.lastModified;
//        sendData.size = uploadFile.size;
//        sendData.type = uploadFile.type;

//        reader.onloadstart = (progress: ProgressEvent) => {
//            this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(false));
//            this.info(undefined);
//            this.info2(undefined);
//            this.fileName(undefined);
//        }

//        // Wenn der Dateiinhalt ausgelesen wurde...
//        reader.onloadend = (progress: ProgressEvent) => {
//            const fileReader = progress.target as FileReader;

//            if (fileReader.result === null) {
//                return;
//            }

//            sendData.fileData = fileReader.result;

//            const success = (result: UploadDataResponsePO) => {
//                this.dataUploadedEvent.emit(new DataUploadedEventArg(uploadFile.name, result.countRecords));
//                this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
//            };

//            const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
//                this.info(jqXHR.responseText);
//                this.info2(jqXHR.responseText);
//                this.infoType("alert-danger");
//                this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
//                this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
//                this.fileName(undefined);
//            };

//            this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
//            this.info(undefined);
//            this.info2(undefined);
//            this.infoType("");

//            postJSON(
//                {
//                    context: this,
//                    data: JSON.stringify(sendData),
//                    error,
//                    success,
//                    url: "/api/ImportService/UploadData",
//                });
//        };

//        reader.readAsDataURL(uploadFile);
//    }
//}

class UploadViewModel {
    public fileName: ko.Observable<string>;
    public fileList: ko.Observable<FileList>;
    public info: ko.Observable<StringOrUndefined>;
    public info2: ko.Observable<StringOrUndefined>;
    public infoType: ko.Observable<string>;
    public showInfo: ko.Computed<boolean>;
    public visibiltyChangedEvent: VisibilityChangedEvent;
    public isBusyChangedEvent: IsBusyChangedEvent;
    public dataUploadedEvent: DataUploadedEvent;
    public countRecords: ko.Observable<NumberOrNull>;
    public countDublettes: ko.Observable<NumberOrNull>;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor() {
        this.fileName = ko.observable("");
        this.infoType = ko.observable("");
        this.info = ko.observable(undefined);
        this.info2 = ko.observable(undefined);
        this.countRecords = ko.observable(null);
        this.countDublettes = ko.observable(null);
        this.visibiltyChangedEvent = new VisibilityChangedEvent();
        this.dataUploadedEvent = new DataUploadedEvent();
        this.isBusyChangedEvent = new IsBusyChangedEvent();
        this.showInfo = ko.computed(() => {
            return this.infoType() !== "";
        }, this);
    }

    public submitForm = (oFormElement: HTMLFormElement) => {
        const formData = new FormData(oFormElement);

        this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
        this.info(undefined);
        this.info2(undefined);
        this.infoType("");

        fetch(oFormElement.action, {
            method: 'POST',
            headers: {
                'RequestVerificationToken': getCookie('RequestVerificationToken')
            },
            body: formData
        }).then(response => {
            if (!response.ok) {
                const errorText = response.text().then(errorText => {
                    this.info(errorText);
                    this.info2(errorText);
                    this.infoType("alert-danger");
                    this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
                    this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
                    this.fileName(undefined);
                });
            }
            else {
                response.json().then(json => {
                    const uploadResult: UploadDataResponsePO = json;
                    this.dataUploadedEvent.emit(new DataUploadedEventArg(uploadResult.fileName, uploadResult.countRecords));
                    this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
                });
            }
        });

        return false;
    }

    public getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    // -----------------------------------------------------------
    // UI-Ereignisse
    // -----------------------------------------------------------
    public upload = (model: UploadViewModel, evt: Event) => {
        const input = evt.target as HTMLInputElement;
        const form = document.querySelector("#uploadForm") as HTMLFormElement;
        const btn = document.querySelector("#submitBtn") as HTMLButtonElement;

        if (input.files === null) {
            return;
        }

        if (input.files.length === 0) {
            return;
        }

        btn.click();
    }
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

// -----------------------------------------------------------
// AssignmentAccountViewModel
// -----------------------------------------------------------
class AssignmentAccountViewModel {
    public visible: ko.Observable<boolean>;
    public messageResultVisible: ko.Observable<boolean>;
    public messageUploadVisible: ko.Observable<boolean>;
    public isBusyChangedEvent: IsBusyChangedEvent;
    public listRecords: ko.Observable<NumberOrNull>;
    public dublettes: ko.Observable<NumberOrNull>;
    public importedRecords: ko.Observable<NumberOrNull>;
    public identified: ko.Observable<NumberOrNull>;
    public failed: ko.Observable<NumberOrNull>;
    public possiblyIdentified: ko.Observable<NumberOrNull>;
    public eventuallyIdentified: ko.Observable<NumberOrNull>;
    public notIdentified: ko.Observable<NumberOrNull>;
    public durationRequest: ko.Observable<StringOrNull>;
    public countRecords: ko.Observable<NumberOrNull>;
    public fileName: ko.Observable<StringOrNull>;
    public durationUpload: ko.Observable<StringOrNull>;
    public info: ko.Observable<StringOrUndefined>;
    public info2: ko.Observable<StringOrUndefined>;
    public infoType: ko.Observable<string>;
    public showInfo: ko.Computed<boolean>;
    public url: ko.Observable<StringOrNull>;
    public messageVisible: ko.Computed<boolean>;
    public taskMessageVisible: ko.Computed<boolean>;
    public visibiltyChangedEvent: VisibilityChangedEvent;
    public identifiedEvent: IdentifiedEventEvent;
    public phase: ko.Observable<string>;
    public progressMessage: ko.Observable<string>;
    public elapsedTime: ko.Observable<string>;
    public remainingTime: ko.Observable<string>;
    public processedSteps: ko.Observable<string>;
    public remainingSteps: ko.Observable<string>;
    private connection: signalR.HubConnection;
    private packageId: string;
    private userId: string;
    public answerUrl: string;

    // -----------------------------------------------------------
    // Konstruktor
    // -----------------------------------------------------------
    constructor() {

        this.messageResultVisible = ko.observable(false);
        this.messageUploadVisible = ko.observable(false);
        this.countRecords = ko.observable(null);
        this.fileName = ko.observable(null);
        this.isBusyChangedEvent = new IsBusyChangedEvent();
        this.identifiedEvent = new IdentifiedEventEvent();
        this.infoType = ko.observable("");
        this.info = ko.observable(undefined);
        this.info2 = ko.observable(undefined);
        this.dublettes = ko.observable(0);
        this.importedRecords = ko.observable(0);
        this.identified = ko.observable(0);
        this.failed = ko.observable(0);
        this.possiblyIdentified = ko.observable(0);
        this.notIdentified = ko.observable(0);
        this.eventuallyIdentified = ko.observable(0);
        this.listRecords = ko.observable(0);
        this.durationRequest = ko.observable(null);
        this.durationUpload = ko.observable(null);
        this.phase = ko.observable();
        this.progressMessage = ko.observable();
        this.remainingTime = ko.observable();
        this.elapsedTime = ko.observable();
        this.processedSteps = ko.observable();
        this.remainingSteps = ko.observable();
        this.showInfo = ko.computed(() => {
            return this.infoType() !== "";
        }, this);
        this.url = ko.observable(null);
        this.messageVisible = ko.computed(() => {
            return this.messageUploadVisible() || this.messageResultVisible();
        }, this);
        this.visibiltyChangedEvent = new VisibilityChangedEvent();
        this.taskMessageVisible = ko.computed(() => {
            return this.messageUploadVisible() || this.messageResultVisible();
        }, this);
    }

    // -----------------------------------------------------------
    // Benutzerereignisse
    // -----------------------------------------------------------
    public onVisibilityChanged = (event: VisibilityChangedEventArg) => {
        this.messageResultVisible(event.visible);
    }

    public onDataUploaded = (event: DataUploadedEventArg) => {
        this.countRecords(event.countRecords);
        this.fileName(event.fileName);
        this.messageUploadVisible(true);
        this.messageResultVisible(false);
        this.loadData(true);
    }

    public onUpdateStatistics = (event: UpdateStatisticsEventArg) => {
        this.loadStatistics(event.result);
    }

    private session_id = () => {
        var matches = /SESS\w*ID=([^;]+)/i.exec(document.cookie);
        return matches != null ? matches[matches.length - 1] : false;
    }

    public loadData = (forceSpinner: boolean) => {
        this.connection = new signalR.HubConnectionBuilder().withUrl("/notify").build();

        this.connection.on("process", (progress: ProgressPO) => {
            this.phase(`${progress.progress}%`);
            this.progressMessage(`${progress.progress} % (${progress.phase})`);
        })

        this.connection.on("finished", (connection) => {

            const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
                this.info(jqXHR.responseText);
                this.info2(jqXHR.responseText);
                this.infoType("alert-danger");
                this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
                this.messageResultVisible(true);
                this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
            };

            const success = (result: AnswerResultPO) => {
                const self = this as AssignmentAccountViewModel;

                self.dublettes(result.dublettes);
                self.importedRecords(result.importedRecords);
                self.identified(result.identified);
                self.failed(result.failed);
                self.possiblyIdentified(result.possiblyIdentified);
                self.eventuallyIdentified(result.eventuallyIdentified);
                self.notIdentified(result.notIdentified);
                self.listRecords(result.listRecords);
                self.durationRequest(result.durationRequest);
                self.durationUpload(result.durationUpload);
                self.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
                self.messageResultVisible(true);
                self.url("Report/Index/" + result.listID);
                this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
                this.identifiedEvent.emit(new IdentifiedEventArg());
                this.info(undefined);
                this.info2(undefined);
                connection.disconnect();
            };

            this.info(undefined);
            this.info2(undefined);
            this.infoType("");

            
            getJSON(
                {
                    context: this,
                    contentType: "application/json",
                    error,
                    success,
                    url: `/api/ImportService/GetAnswerResult/?userId=${this.userId}&packageId=${this.packageId}`
                });
        });

        this.connection.start().then(() => {
            const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
                this.info(jqXHR.responseText);
                this.info2(jqXHR.responseText);
                this.infoType("alert-danger");
                this.isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
                this.messageResultVisible(true);
                this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
            };

            const success = (result: UploadResultPO) => {
                const self = this as AssignmentAccountViewModel;

                self.dublettes(result.dublettes);
                self.importedRecords(result.importedRecords);
                self.identified(result.identified);
                self.failed(result.failed);
                self.possiblyIdentified(result.possiblyIdentified);
                self.eventuallyIdentified(result.eventuallyIdentified);
                self.notIdentified(result.notIdentified);
                self.listRecords(result.listRecords);
                self.durationRequest(result.durationRequest);
                self.durationUpload(result.durationUpload);
                self.isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
                self.packageId = result.packageId;
                self.userId = result.userId;
                self.connection.send("connect", result.packageId, result.userId);
            }

            getJSON(
                {
                    context: this,
                    error,
                    success,
                    url: "/api/ImportService/GetUploadResult",
                });
        });
    }

    private loadStatistics = (result: UploadResultPO) => {
        const self = this as AssignmentAccountViewModel;

        self.dublettes(result.dublettes);
        self.importedRecords(result.importedRecords);
        self.identified(result.identified);
        self.possiblyIdentified(result.possiblyIdentified);
        self.notIdentified(result.notIdentified);
        self.listRecords(result.listRecords);
        self.messageResultVisible(true);
    }
}

class TabViewModel {

    public assignmentAccountViewModel: ko.Observable<AssignmentAccountViewModel>;
    public newAccountGridModel: ko.Observable<NewAccountGridModel>;
    public accountProposalGridModel: ko.Observable<AccountProposalGridModel>;
    public visible: ko.Observable<boolean>;
    public activeTab: ko.Observable<string>;
    public enable: ko.Observable<boolean>;
    public projects: ko.ObservableArray<ImportServiceProjectPO>;
    public selectedProject: ko.Observable<StringOrNull>;
    public info: ko.Observable<StringOrUndefined>;
    public infoType: ko.Observable<string>;
    public isExpanded: ko.Observable<boolean>;
    public showInfo: ko.Computed<boolean>;
    public leftSide: ko.Observable<string>;
    public rightSide: ko.Observable<string>;
    public opacity: ko.Observable<number>;
    public isBusy: ko.Observable<boolean>;
    public visibiltyChangedEvent: VisibilityChangedEvent;
    public updateStatisticsEvent: UpdateStatisticsEvent;

    constructor(
        assignmentAccountModel: AssignmentAccountViewModel,
        newAccountGrid: NewAccountGridModel,
        accountProposalGrid: AccountProposalGridModel) {
        this.visibiltyChangedEvent = new VisibilityChangedEvent();
        this.updateStatisticsEvent = new UpdateStatisticsEvent();
        this.opacity = ko.observable(0);
        this.assignmentAccountViewModel = ko.observable(assignmentAccountModel);
        this.newAccountGridModel = ko.observable(newAccountGrid);
        this.accountProposalGridModel = ko.observable(accountProposalGrid);
        this.visible = ko.observable(false);
        this.activeTab = ko.observable("availableAccountView");
        this.enable = ko.observable(false);
        this.projects = ko.observableArray();
        this.selectedProject = ko.observable(null);
        this.infoType = ko.observable("");
        this.info = ko.observable(undefined);
        this.showInfo = ko.computed(() => {
            return this.infoType() !== "";
        }, this);
        this.leftSide = ko.observable("glyphicon glyphicon-triangle-left");
        this.rightSide = ko.observable("glyphicon glyphicon-triangle-right");
        this.isBusy = ko.observable(false);
    }

    public onExpandView = () => {
        const isExpanded = !this.isExpanded();

        this.isExpanded(isExpanded);

        if (isExpanded) {
            this.leftSide("glyphicon glyphicon-triangle-right");
            this.rightSide("glyphicon glyphicon-triangle-left");
        } else {
            this.leftSide("glyphicon glyphicon-triangle-left");
            this.rightSide("glyphicon glyphicon-triangle-right");
        }
    }

    public onVisibilityChanged = (event: VisibilityChangedEventArg) => {
        this.visible(event.visible);
        this.enable(event.visible);
    }

    public onIdentified = (event: IdentifiedEventArg) => {
        this.loadProjectData();
    }

    public result = (activeTab: string) => {
        this.activeTab(activeTab);
    }

    public search = (activeTab: string) => {
        this.activeTab(activeTab);
    }

    public getActiveTab = () => {
        return this.activeTab;
    }

    public onSelectProject(selectedProject: StringOrNull) {
        this.selectedProject(selectedProject);
        const selectedProjectString = this.selectedProject();

        const success = (result: UploadResultPO) => {
            this.info(`Es wurden  ${result.importedRecords} Datensätze ausgelesen`);
            this.infoType("alert-info");
            this.newAccountGridModel().loadDataAndReset(true);
            this.accountProposalGridModel().isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
            this.visibiltyChangedEvent.emit(new VisibilityChangedEventArg(true));
            this.updateStatisticsEvent.emit(new UpdateStatisticsEventArg(result));
        };

        const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
            this.info(`Es ist beim Auslesen Ihres Projekt ein Fehler aufgetreten`);
            this.infoType("alert-danger");
            this.newAccountGridModel().loadDataAndReset(true);
            this.accountProposalGridModel().isBusyChangedEvent.emit(new IsBusyChangedEventArg(false));
        };

        if (selectedProjectString === null || selectedProjectString === "") {
            this.info(`Es wurde kein Projektname festgelegt`);
            this.infoType("alert-danger");
            return;
        }

        const request = new ChangePackageRequest(selectedProjectString);
        this.accountProposalGridModel().isBusyChangedEvent.emit(new IsBusyChangedEventArg(true));
        postJSON(
            {
                context: this,
                data: JSON.stringify(request),
                error,
                success,
                url: "/api/ImportService/ChangeImportData",
            });
    }

    public loadProjectData = () => {

        const success = (result: ImportServiceProjectPO[]) => {
            const self = this as TabViewModel;

            const list = Array();

            result.forEach((a) => {
                list.push(a);
            });

            self.projects(list);
        };

        const error = (jqXHR: JQuery.jqXHR, textStatus: string, errorThrown: string) => {
            textStatus = "";
        };

        getJSON(
            {
                contentType: "application/json",
                context: this,
                error,
                success,
                url: "api/ProjectService/Get",
            });
    }

    private setIsBusy = (isbusy: boolean) => {
        this.isBusy(isbusy);

        if (isbusy) {
            this.opacity = ko.observable(1.0);
        } else {
            this.opacity = ko.observable(0.0);
        }
    }
}

// -----------------------------------------------------------
// OverlayViewModelNew
//
// Beinhaltet Datenupload zum Server
// -----------------------------------------------------------
class OverlayViewModelNew {
    public uploadViewModel: ko.Observable<UploadViewModel>;
    public tabViewModel: ko.Observable<TabViewModel>;
    public rangesViewModel: ko.Observable<RangesViewModel>;
    public isBusy: ko.Observable<boolean>;
    public isExpanded: ko.Observable<boolean>;
    public visible: ko.Observable<boolean>;
    // public enable: ko.Observable<boolean>;
    public opacity: ko.Observable<number>;
    public leftSide: ko.Observable<string>;
    public rightSide: ko.Observable<string>;
    public info: ko.Observable<StringOrUndefined>;
    public infoType: ko.Observable<string>;
    public showInfo: ko.Computed<boolean>;

    constructor(
        uploadView: UploadViewModel,
        tabView: TabViewModel,
        rangesView: RangesViewModel) {

        this.opacity = ko.observable(0);
        this.isBusy = ko.observable(false);
        this.uploadViewModel = ko.observable(uploadView);
        this.tabViewModel = ko.observable(tabView);
        this.rangesViewModel = ko.observable(rangesView);
        this.isExpanded = ko.observable(true);
        this.leftSide = ko.observable("glyphicon glyphicon-triangle-left");
        this.rightSide = ko.observable("glyphicon glyphicon-triangle-right");
        this.visible = ko.observable(false);
        // this.enable = ko.observable(false);
        this.infoType = ko.observable("");
        this.info = ko.observable(undefined);
        this.showInfo = ko.computed(() => {
            return this.infoType() !== "";
        }, this);
    }

    public onVisibilityChanged = (event: VisibilityChangedEventArg) => {
        this.visible(event.visible);
        // this.enable(event.visible);
    }

    public onExpandView = () => {
        const isExpanded = !this.isExpanded();

        this.isExpanded(isExpanded);

        if (isExpanded) {
            this.leftSide("glyphicon glyphicon-triangle-right");
            this.rightSide("glyphicon glyphicon-triangle-left");
        } else {
            this.leftSide("glyphicon glyphicon-triangle-left");
            this.rightSide("glyphicon glyphicon-triangle-right");
        }
    }

    public onIsBusyChanged = (event: IsBusyChangedEventArg) => {
        this.setIsBusy(event.isbusy);
    }

    private setIsBusy = (isbusy: boolean) => {
        this.isBusy(isbusy);

        if (isbusy) {
            this.opacity = ko.observable(1.0);
        } else {
            this.opacity = ko.observable(0.0);
        }
    }
}

// -----------------------------------------------------------
// Initialisierung
// -----------------------------------------------------------
// ko.options.deferUpdates = true;
const newAccountGridModel = new NewAccountGridModel("Name", SortDirection.Ascending, 5);

const accountProposalGridModel =
    new AccountProposalGridModel(
        "Factor",
        SortDirection.Descending,
        5);

const assignmentAccountViewModel
    = new AssignmentAccountViewModel();

const uploadViewModel
    = new UploadViewModel();

const rangesViewModel
    = new RangesViewModel();

const tabViewModel =
    new TabViewModel(
        assignmentAccountViewModel,
        newAccountGridModel,
        accountProposalGridModel);

const overlayViewModel = new OverlayViewModelNew(uploadViewModel, tabViewModel, rangesViewModel);

// -----------------------------------------------------------
// Benutzerereignisse binden
// -----------------------------------------------------------

newAccountGridModel.selectionChangedEvent.on(accountProposalGridModel.onSelectionChanged);
newAccountGridModel.createAccountEvent.on(accountProposalGridModel.onCreateAccount);

accountProposalGridModel.assignElementEvent.on(newAccountGridModel.onAssignElement);

uploadViewModel.visibiltyChangedEvent.on(overlayViewModel.onVisibilityChanged);
uploadViewModel.dataUploadedEvent.on(assignmentAccountViewModel.onDataUploaded);
uploadViewModel.isBusyChangedEvent.on(overlayViewModel.onIsBusyChanged);

assignmentAccountViewModel.isBusyChangedEvent.on(overlayViewModel.onIsBusyChanged);
assignmentAccountViewModel.visibiltyChangedEvent.on(tabViewModel.onVisibilityChanged);
assignmentAccountViewModel.visibiltyChangedEvent.on(newAccountGridModel.onVisibilityChanged);
assignmentAccountViewModel.visibiltyChangedEvent.on(accountProposalGridModel.onVisibilityChanged);
assignmentAccountViewModel.identifiedEvent.on(tabViewModel.onIdentified);

tabViewModel.visibiltyChangedEvent.on(newAccountGridModel.onVisibilityChanged);
tabViewModel.visibiltyChangedEvent.on(accountProposalGridModel.onVisibilityChanged);
tabViewModel.updateStatisticsEvent.on(assignmentAccountViewModel.onUpdateStatistics);

overlayViewModel.rangesViewModel().loadData();

// -----------------------------------------------------------
// Knockout-Bindungen
// -----------------------------------------------------------
ko.applyBindings(overlayViewModel, $("#overlayViewModel")[0]);

overlayViewModel.tabViewModel().loadProjectData();
