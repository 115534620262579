export class NewAccountPO {
    public id: number;
    public extId: string;
    public name: string;
    public accountProposal: string;
    public accountProposalId: number;
    public companyProposal: string;
    public companyProposalId: number;
    public city: string;
    public domain: string;
    public factor: number;
    public fullName: string;
}

export class AccountProposalPO {
    public id: number;
    public name: string;
    public factor: number;
}

export class NewAccountResultPO {
    public newAccounts: NewAccountPO[];
    public totalCount: number;
}

export class AccountProposalResultPO {
    public accountProposals: AccountProposalPO[];
    public totalCount: number;
}

export class ImportServiceProjectPO {
    public productId: number;
    public name: string;
}

export class UploadDataResponsePO {
    public fileName: string;
    public countRecords: number;
    public countDublettes: number;
    public isRestrictedCount: boolean;
}

export class ImportDataResponsePO {
    public importedRecords: number;
}

export class ChangePackageResponsePO {
    public importedRecords: number;
}

export class NewAccountUpdateResponsePO {
    public success: boolean;
}

export class UploadResultPO {
    public listRecords: number;
    public dublettes: number;
    public importedRecords: number;
    public identified: number;
    public failed: number;
    public possiblyIdentified: number;
    public eventuallyIdentified: number;
    public notIdentified: number;
    public durationRequest: string | null;
    public durationUpload: string | null;
    public fileName: string | null;
    public listID: number;
    public projectID: number;
}

export class RangePO {
    public range: string;
    public count: number;
}

export class RangeResponsePO {
    public ranges: RangePO[];
}

export class ProgressPO {
    public phase: string;
    public progress: number;
    public total: number;
}
